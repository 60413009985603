import { useState, useEffect, useRef } from 'react'

type useTimerProps = {
    limit: number
    onStart?: () => void
    onForceEnd?: () => void
    onNaturalEnd?: () => void
}

type returnType = {
    startTimer: () => void
    endTimer: () => void
    time: number
}

export const useTimer = ( {
    limit,
    onStart,
    onForceEnd,
    onNaturalEnd,
}: useTimerProps ): returnType => {
    const [ intervalID, setIntervalID ] = useState<NodeJS.Timeout | null>( null )
    const intervalIDRef = useRef( intervalID )
    useEffect( () => {
        intervalIDRef.current = intervalID
    }, [ intervalID ] )
    const [ time, setTime ] = useState( 0 )
    const timeRef = useRef( time )
    useEffect( () => {
        timeRef.current = time
    }, [ time ] )

    const startTimer = () => {
        const newIntervalID = setInterval( () => {
            timeRef.current + 1 < limit ?
                incrementTime() : onNturalEndTimer()
        }, 1000 )
        setIntervalID( newIntervalID )
        onStart && onStart()
    }

    const incrementTime = () => setTime( timeRef.current + 1 )

    const endTimer = () => {
        intervalIDRef.current && clearInterval( intervalIDRef.current )
        setTime( 0 )
        onForceEnd && onForceEnd()
    }

    const onNturalEndTimer = () => {
        intervalIDRef.current && clearInterval( intervalIDRef.current )
        setTime( 0 )
        onNaturalEnd && onNaturalEnd()
    }

    return {
        startTimer,
        endTimer,
        time,
    }
}
