import React, { FC, useState, useEffect, useRef } from 'react'
import { useTimer } from '../hooks/useTimer'
import VideoDrawModal from '../templates/VideoDrawModal'
import { WebViewVideoAction, WebViewVideoActionType } from '../types/WebViewVideoAction'


const ENVIRONMENT = process.env.ENVIRONMENT

type Props = {
}

const VideoDrawModalPage: FC<Props> = ( { } ) => {

    const [ videoUrl, setVideoUrl ] = useState( "" );
    const [ drawModalVisible, setDrawModalVisible ] = useState( false );
    const [ videoLoadStateWitchIntervalId, setVideoLoadStateWitchIntervalId ] = useState<NodeJS.Timeout | null>( null );
    const videoLoadStateWitchIntervalIdRef = useRef( videoLoadStateWitchIntervalId )
    const videoRef = useRef<HTMLVideoElement>( null )
    const { startTimer } = useTimer( { limit: 3, onNaturalEnd: () => setDrawModalVisible( true ) } )

    useEffect( () => {
        videoLoadStateWitchIntervalIdRef.current = videoLoadStateWitchIntervalId
    }, [ videoLoadStateWitchIntervalId ] )

    useEffect( () => {
        const url = location.search.split( '?src=' )[ 1 ]
        const time = location.hash.split( "#t=" )[ 1 ]
        Number( time ) === 0 ?
            setVideoUrl( url + "#t=" + "0.0001" ) :
            setVideoUrl( url + "#t=" + time )
        startTimer()
    }, [] )

    useEffect( () => {
        fixBackground()
    }, [] )

    const fixBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflow = "hidden"
        document.getElementsByTagName( "body" )[ 0 ].style.overflow = "hidden"
    }

    // useEffect( () => {
    //     const intervalID =
    //         setInterval( () => {
    //             const videoIsLoaded = checkVideoIsLoaded()
    //             console.log( videoIsLoaded )
    //             if ( !videoIsLoaded ) return
    //             setDrawModalVisible( true )
    //             videoLoadStateWitchIntervalIdRef.current && clearInterval( videoLoadStateWitchIntervalIdRef.current )
    //         }, 300 )
    //     setVideoLoadStateWitchIntervalId( intervalID )
    //     return () => {
    //         videoLoadStateWitchIntervalIdRef.current && clearInterval( videoLoadStateWitchIntervalIdRef.current )
    //     }
    // }, [] )

    // const checkVideoIsLoaded = () => videoRef.current ?
    //     3 <= videoRef.current.readyState :
    //     false

    const webViewDispatch = ( type: WebViewVideoActionType ) => {
        let data
        switch ( type ) {
            case WebViewVideoAction.START_SEEK:
                data = { event: WebViewVideoAction.START_SEEK }
                break
            case WebViewVideoAction.END_SEEK:
                data = { event: WebViewVideoAction.END_SEEK }
                break
            case WebViewVideoAction.START_DRAW:
                const canvasBackGround = document.getElementById( "canvas_background" )
                data = {
                    event: WebViewVideoAction.START_DRAW,
                    height: String( canvasBackGround ?
                        canvasBackGround.getBoundingClientRect().height
                        : 300 )
                }
                break
            case WebViewVideoAction.END_DRAW:
                data = { event: WebViewVideoAction.END_DRAW }
                break
            case WebViewVideoAction.ADD_IMAGE_TO_COMMENT:
                data = {
                    event: WebViewVideoAction.ADD_IMAGE_TO_COMMENT,
                    image: window.commentFormFile,
                    srcURL: window.canvasImageURL
                }
                break
            case WebViewVideoAction.DOWNLOAD_CANVAS_IMAGE:
                data = {
                    event: WebViewVideoAction.DOWNLOAD_CANVAS_IMAGE,
                    image: window.canvasImageFile,
                    srcURL: window.canvasImageURL
                }
                break
        }
        try {
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage( JSON.stringify( data ) );
        }
        catch ( error ) {
            alert( error )
        }
    }

    const videoScale = videoRef.current ?
        Number( videoRef.current.style.transform.split( ',' ).slice( -1 )[ 0 ].slice( 0, -1 ) ) || 1 :
        1

    return (
        <div>
            <video
                ref={ videoRef }
                src={ videoUrl }
                style={ { opacity: 0, visibility:"hidden" } }
                // onError={(e)=>console.log(String(e))}
                onLoad={ () => setDrawModalVisible( true ) }
                onCanPlayThrough={ () => setDrawModalVisible( true ) }
                preload="auto"
                crossOrigin={ ENVIRONMENT === "production" ? "anonymous" : "" }></video>
            {drawModalVisible && videoRef.current &&
                <VideoDrawModal
                    video={ videoRef.current }
                    videoScale={ videoScale }
                    closeModal={ () => { } }
                    forWebView={ true }
                    webViewDispatch={ webViewDispatch } /> }
        </div>
    )
}
export default VideoDrawModalPage
